/* eslint react/prop-types: 0, react/display-name: 0 */
import React from 'react';
import loadable from '@loadable/component';
import { LocaleProvider } from 'context/LocaleProvider';
import { HeaderProvider } from 'context/HeaderProvider';
import { LoadedProvider } from 'context/LoaderProvider';
import GlobalStyle from './src/utils/styles/global';
import theme from './src/utils/styles/theme';
import { ThemeProvider } from 'styled-components';
import SEO from 'components/SEO';
// import ClientOnly from 'components/ClientOnly';
import 'swiper/swiper-bundle.min.css';

const Footer = loadable(() => import('components/Footer'), {
  fallback: <div />,
});

const Header = loadable(() => import('components/Header'), {
  fallback: <div />,
});

const wrapPageElement = ({ element, props }) => {
  const node = props?.data?.allContentfulPage?.edges?.[0]?.node;

  return (
    <>
      <SEO
        {...node?.seo}
        components={node?.components}
        location={props?.location}
        locale={props?.pageContext?.locale}
        tags={props?.pageContext?.tags}
        pageContext={props?.pageContext}
      />
      <LocaleProvider locale={props.pageContext.locale}>
        <LoadedProvider>
          <HeaderProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              {/* <ClientOnly>
              </ClientOnly> */}
              <Header location={props.location} />
              <main>{element}</main>
              <Footer />
            </ThemeProvider>
          </HeaderProvider>
        </LoadedProvider>
      </LocaleProvider>
    </>
  );
};

export default wrapPageElement;
