import React, { createContext, useState, useEffect } from 'react'
import i18n from '../../config/i18n'
import { useSessionStorage } from 'react-use'
import detectBrowserLanguage from 'detect-browser-language'

const LocaleContext = createContext()

export const LocaleProvider = ({ children, locale }) => {
  const [selectedLocale, setSelectedLocale] = useSessionStorage('language', 'en-CA')
  const [localePageContext, setLocalePageContext] = useState(null)

  // useEffect(() => {
  //   if (!selectedLocale) {
  //     detectBrowserLanguage()?.includes('fr') ? setSelectedLocale('fr-CA') : setSelectedLocale('en-CA')
  //   }
  // }, [])

  return (
    <LocaleContext.Provider
      value={{
        locale,
        i18n,
        setSelectedLocale,
        selectedLocale,
        setLocalePageContext,
        localePageContext,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export default LocaleContext
