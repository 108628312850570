import React, { createContext, useState } from 'react';

const LoaderContext = createContext();

export const LoadedProvider = ({ children }) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  return (
    <LoaderContext.Provider
      value={{
        hasLoaded,
        setHasLoaded,
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderContext;
