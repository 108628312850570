const website = require('./website');

module.exports = {
  'en-CA': {
    default: true,
    path: 'en',
    locale: 'en-CA',
    siteLanguage: 'en',
    ogLang: 'en_ca',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    category: 'Category',
    categories: 'Categories',
    was: 'was',
    were: 'were',
    tagged: 'tagged with',
    recent: 'Recent',
    projects: 'projects',
    allCategories: 'All categories',
    entries: 'entries',
  },
  'fr-CA': {
    default: false,
    path: 'fr',
    locale: 'fr-CA',
    siteLanguage: 'fr',
    ogLang: 'fr_CA',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription:
      "Obtenez 3 soumissions en 48 heures de fournisseurs qualifiés et sérieux spécialisés en marketing web - C'est gratuit et simple!",
    headline: 'Schreiben und Veröffentlichen für LekoArts',
    category: 'Categorie',
    categories: 'Categories',
    was: 'wurde',
    were: 'wurden',
    tagged: 'markiert mit',
    recent: 'Neue',
    projects: 'Projekte',
    allCategories: 'Alle Kategorien',
    entries: 'Einträge',
  },
};
