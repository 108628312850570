import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = (props) => {
  const {
    seoTitle: title,
    seoDescription: description,
    locale,
    seoSchema: schema,
    openGraphTitle: ogGraphTitle,
    openGraphDescription,
    openGraphImage,
    openGraphTypes,
    location,
    components,
    pageContext,
  } = props;

  const { site } = useStaticQuery(query);

  const {
    siteMetadata: {
      siteUrl,
      defaultBanner,
      ogLanguage,
      author,
      twitter,
      facebook,
    },
  } = site;
  let schemaOrg = null;

  let seoTitle = title;
  let seoDescription = description;
  let ogTitle = ogGraphTitle || title;
  let ogDescription = openGraphDescription || description;
  let ogImage = openGraphImage?.file?.url;

  return (
    <Helmet title={seoTitle}>
      <html lang={locale?.includes('fr') ? 'fr' : 'en'} />
      <meta
        content='width=device-width,initial-scale=1.0,user-scalable=yes'
        name='viewport'
      />
      <meta
        name='facebook-domain-verification'
        content='0bcpfz8ycuopwdcfu0sri805e62tuo'
      />
      <meta content='yes' name='apple-mobile-web-app-capable' />
      <meta
        content='black-translucent'
        name='apple-mobile-web-app-status-bar-style'
      />
      <meta content={seoTitle} name='apple-mobile-web-app-title' />
      <meta name='description' content={seoDescription} />
      <meta name='image' content={ogImage} />
      <meta name={seoTitle} content={seoDescription} />
      {/* Facebook */}
      {facebook && <meta property='og:site_name' content={facebook} />}
      <meta property='og:locale' content={ogLanguage} />
      <meta property='og:url' content={schema?._url || location.href} />
      <meta property='og:type' content={openGraphTypes} />
      <meta property='og:title' content={ogTitle} />
      <meta property='og:description' content={ogDescription} />
      <meta property='og:image' content={ogImage} />
      <meta content='1024' property='og:image:width' />
      <meta content='512' property='og:image:height' />
      <meta property='og:image:alt' content={ogDescription} />
      {/* Twitter */}
      {twitter && <meta name='twitter:creator' content={twitter} />}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={ogTitle} />
      <meta name='twitter:description' content={ogDescription} />
      <meta name='twitter:image' content={ogImage} />
      <meta content='1024' name='twitter:image:width' />
      <meta content='512' name='twitter:image:height' />
      <meta name='twitter:image:alt' content={ogDescription} />
      {schemaOrg && (
        <script type='application/ld+json'>{JSON.stringify(schemaOrg)}</script>
      )}
      {process.env.NODE_ENV === 'production' && (
        <>
          <script type='text/javascript'>
            {`
            window.intercomSettings = {
              app_id: ${process.env.INTERCOM_ID}
              };
          `}
          </script>
          <script type='text/javascript'>
            {`
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.INTERCOM_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
          </script>
        </>
      )}
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
  locale: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  node: null,
  locale: 'en-CA',
};

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultBanner: banner
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`;
